import { render, staticRenderFns } from "./physicianTeam.vue?vue&type=template&id=9f664382&scoped=true&"
import script from "./physicianTeam.vue?vue&type=script&lang=js&"
export * from "./physicianTeam.vue?vue&type=script&lang=js&"
import style0 from "./physicianTeam.vue?vue&type=style&index=0&id=9f664382&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f664382",
  null
  
)

export default component.exports