<template>
  <div>
    <header>
      <div class="searchInput">
        <input
          type="text"
          v-model="keyword"
          :placeholder="placeholder"
          @focus="placeholder = ''"
          @blur="placeholder = '搜索科室、医生'"
          @keyup.enter="handleSearch($event)"
        />
        <i class="fangdajing"></i>
      </div>
    </header>
    <section>
      <div class="grid_title">临床科室</div>
      <div class="grid-item">
        <van-grid>
          <van-grid-item
            v-for="(item, index) in doctorlist"
            :key="index"
            @click="
              $router.push(
                '/deptPage?title=' + item.name + '&code=' + item.code
              )
            "
          >
            <template #text>
              <span class="text">{{ item.name }}</span>
            </template>
            <template #icon>
              <i :class="item.name"></i>
            </template>
          </van-grid-item>
        </van-grid>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "physicianTeam",
  data() {
    return {
      keyword: "",
      placeholder: "搜索科室、医生",
      doctorlist: [],
    };
  },
  methods: {
    // 搜索框回车搜索
    handleSearch(e) {
      var evt = window.event || e;
      if (evt.keyCode == 13) {
        //回车事件
        this.$router.push({
          path: "/searchPage",
          query: {
            keyword: this.keyword,
          },
        });
      }
    },
    getDortorList() {
      let postData = {
        key: this.keyword,
      };
      this.$http.get("/api/dept", postData).then((res) => {
        if (res.status == 200) {
          this.doctorlist = res.data;
        }
      });
    },
  },
  mounted() {
    this.getDortorList();
  },
};
</script>

<style scoped>
header {
  font-size: 0.4rem;
  position: relative;
}
.searchInput {
  height: 1rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  text-align: right;
  width: 9rem;
  background-color: #fff;
  margin-left: 0.42rem;
  position: relative;
  top: 0.3rem;
  margin-bottom: 0.5rem;
}
.searchInput input {
  outline: none;
  height: 100%;
  border: none;
}
.text {
  font-size: 0.3rem;
}
.grid_title {
  font-size: 0.5rem;
  font-weight: bold;
  text-align: left;
  margin-left: 10px;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.grid_title:before {
  content: "";
  display: inline-block;
  width: 0.1rem;
  height: 0.5rem;
  margin: 0 10px;
  border-radius: 2px;
  background-color: #00d48b;
  position: relative;
  top: 3px;
}
.grid-item {
  width: 9rem;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 auto;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

/deep/.van-grid-item__content {
  height: auto;
}
.van-search__content {
  background-color: white;
  border-radius: 1rem;
}
/deep/.van-hairline:after {
  border: none;
}
</style>
